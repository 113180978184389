import { PayloadAction } from '@reduxjs/toolkit';
import { take, select, call, put } from 'redux-saga/effects';
import {
  authenticateNumRequest,
  authenticateNumSuccess,
  authenticateNumFailure,
  sendAuthenticationNumRequest,
  sendAuthenticationNumSuccess,
  sendAuthenticationNumFailure,
} from 'store/reducers/auth';
import { fetchApi } from 'store/sagas/createFetchAction';
import { RootState } from 'store/reducers';
import {
  IAuthNumRequestPayload,
  ISendAuthNumRequestPayload,
} from 'store/types';
import {
  createPhoneVerification,
  createPhoneVerificationId,
  createPhoneVerificationPw,
  checkPhoneVerificationId,
  checkPhoneVerificationPw,
  checkPhoneVerification,
} from 'api';
import { ICertResponse, IResponse } from '@types';
import { goNextStep } from 'store/reducers/register';

function* handleSendedResult() {
  const { message, status }: ICertResponse = yield select(
    (state: RootState) => state.authReducer.certResponse,
  );

  if (status !== 'success') {
    if (!message) {
      alert('인증번호 발급에 실패했습니다.');
    }
    return;
  }

  alert('인증번호가 전송되었습니다.');
}

function* handleAuthResult(isFindId?: boolean, isFindPw?: boolean) {
  const { message, status }: IResponse = yield select(
    (state: RootState) => state.authReducer.authenticationRes,
  );

  if (status !== 'success') {
    if (!message) {
      alert('요청에 실패했습니다.');
    }
    return;
  }

  if (!isFindId && !isFindPw) {
    yield put(goNextStep());
  }
}

export function* sendAuthenticationNumSaga() {
  while (true) {
    const action: PayloadAction<ISendAuthNumRequestPayload> = yield take(
      sendAuthenticationNumRequest.type,
    );
    const { isFindId, isFindPw, phoneNum, username } = action.payload;

    yield call<any>(
      fetchApi,
      isFindId
        ? createPhoneVerificationId
        : isFindPw
        ? createPhoneVerificationPw
        : createPhoneVerification,
      action.type,
      sendAuthenticationNumSuccess,
      sendAuthenticationNumFailure,
      isFindPw ? { phone: phoneNum, username } : phoneNum,
    );

    yield handleSendedResult();
  }
}

export function* authenticateNumSaga() {
  while (true) {
    const action: PayloadAction<IAuthNumRequestPayload> = yield take(
      authenticateNumRequest.type,
    );
    const { isFindId, isFindPw, id, certNum } = action.payload;

    yield call<any>(
      fetchApi,
      isFindId
        ? checkPhoneVerificationId
        : isFindPw
        ? checkPhoneVerificationPw
        : checkPhoneVerification,
      action.type,
      authenticateNumSuccess,
      authenticateNumFailure,
      { id, certNum },
    );

    yield handleAuthResult(isFindId, isFindPw);
  }
}
