import { takeLatest, all, takeEvery, put, select } from 'redux-saga/effects';
import {
  refreshMyInfoRequest,
  getMyInfoFailure,
  getMyInfoRequest,
  getMyInfoSuccess,
  setUserBriefFailure,
  setUserBriefRequest,
  setUserBriefSuccess,
  setUserSnsFailure,
  setUserSnsRequest,
  setUserSnsSuccess,
  toggleMarketingAgreementFailure,
  toggleMarketingAgreementRequest,
  toggleMarketingAgreementSuccess,
  updateNicknameFailure,
  updateNicknameRequest,
  updateNicknameSuccess,
  updatePasswordFailure,
  updatePasswordRequest,
  updatePasswordSuccess,
  updateThumbnailFailure,
  updateThumbnailRequest,
  updateThumbnailSuccess,
} from 'store/reducers/myInfo';
import {
  refreshMyInfo,
  setUserBrief,
  setUserSNS,
  getMyInfo,
  updateNickname,
  updatePassword,
  updateThumbnail,
  toggleMarketingAgreement,
  getUserPhoneWithTempJwt,
} from 'api';
import { clickLogout } from 'store/reducers/logout';
import isChrome from 'utils/isChrome';
import { IMedia, IMyInfo } from '@types';
import {
  getDormantUserPhoneFailure,
  getDormantUserPhoneRequest,
  getDormantUserPhoneSuccess,
} from 'store/reducers/register';
import { createFetchAction } from './createFetchAction';
import { customHistory } from '../../App';
import { failure } from './failure';

function* toggleMarketingAgreementSaga() {
  yield takeLatest(
    toggleMarketingAgreementRequest,
    createFetchAction(
      toggleMarketingAgreement,
      toggleMarketingAgreementSuccess,
      toggleMarketingAgreementFailure,
      undefined,
      failure,
    ),
  );
}

function* updateNicknameSaga() {
  yield takeLatest(
    updateNicknameRequest.type,
    createFetchAction(
      updateNickname,
      updateNicknameSuccess,
      updateNicknameFailure,
      function* success() {
        yield all([
          customHistory.replace('/mypage/info/edit'),
          customHistory.goBack(),
        ]);

        if (!isChrome()) {
          yield put(refreshMyInfoRequest());
        }
      },
    ),
  );
}

function* updatePasswordSaga() {
  yield takeLatest(
    updatePasswordRequest.type,
    createFetchAction(
      updatePassword,
      updatePasswordSuccess,
      updatePasswordFailure,
      function* success() {
        yield alert(
          '비밀번호가 변경되었습니다. 변경된 번호로 다시 로그인해주세요.',
        );
        yield put(clickLogout(!!window.BootpayRNWebView));
      },
      failure,
    ),
  );
}

function* updateThumbnailSaga() {
  yield takeLatest(
    updateThumbnailRequest.type,
    createFetchAction(
      updateThumbnail,
      updateThumbnailSuccess,
      updateThumbnailFailure,
      function* success() {
        yield alert('프로필 사진이 변경되었습니다.');
        window.location.reload();
      },
      failure,
    ),
  );
}

function* getMyInfoSaga() {
  yield takeEvery(
    getMyInfoRequest.type,
    createFetchAction(
      getMyInfo,
      getMyInfoSuccess,
      getMyInfoFailure,
      function* success(myInfo: IMyInfo) {
        // myInfoData 에 thumnail, brief 가 없을 경우 에러 발생으로 초기 데이터 생성 / ywroh / 2022.03.14
        const myInfoData = JSON.parse(JSON.stringify(myInfo));
        const data = { thumbnail: {} as IMedia, brief: '' } as IMyInfo;
        Object.assign(data, myInfoData);
        if (data.nickname !== localStorage.getItem('nickname')) {
          yield localStorage.setItem('nickname', data.nickname);
        }
        if (
          !!data.thumbnail.url &&
          data.thumbnail.url !== localStorage.getItem('thumbnail')
        ) {
          yield localStorage.setItem('thumbnail', data.thumbnail.url);
        }
        if (!!data.brief && data.brief !== localStorage.getItem('brief')) {
          yield localStorage.setItem('brief', data.brief);
        }
      },
      failure,
    ),
  );
}
function* refreshMyInfoSaga() {
  yield takeLatest(
    refreshMyInfoRequest.type,
    createFetchAction(
      refreshMyInfo,
      getMyInfoSuccess,
      getMyInfoFailure,
      function* success(myInfo: IMyInfo) {
        // myInfoData 에 thumnail, brief 가 없을 경우 에러 발생으로 초기 데이터 생성 / ywroh / 2022.03.14
        const myInfoData = JSON.parse(JSON.stringify(myInfo));
        const data = { thumbnail: {} as IMedia, brief: '' } as IMyInfo;
        Object.assign(data, myInfoData);
        if (data.nickname !== localStorage.getItem('nickname')) {
          yield localStorage.setItem('nickname', data.nickname);
        }
        if (
          !!data.thumbnail.url &&
          data.thumbnail.url !== localStorage.getItem('thumbnail')
        ) {
          yield localStorage.setItem('thumbnail', data.thumbnail.url);
        }
        if (!!data.brief && data.brief !== localStorage.getItem('brief')) {
          yield localStorage.setItem('brief', data.brief);
        }
      },
      failure,
    ),
  );
}

function* setUserBriefSaga() {
  yield takeLatest(
    setUserBriefRequest.type,
    createFetchAction(
      setUserBrief,
      setUserBriefSuccess,
      setUserBriefFailure,
      function* success() {
        yield all([
          customHistory.replace('/mypage/info/edit'),
          customHistory.goBack(),
        ]);

        if (!isChrome()) {
          yield put(refreshMyInfoRequest());
        }
      },
      failure,
    ),
  );
}

function* setUserSnsSaga() {
  yield takeLatest(
    setUserSnsRequest.type,
    createFetchAction(
      setUserSNS,
      setUserSnsSuccess,
      setUserSnsFailure,
      function* success() {
        yield all([
          customHistory.replace('/mypage/info/edit'),
          customHistory.goBack(),
        ]);

        if (!isChrome()) {
          yield put(refreshMyInfoRequest());
        }
      },
      failure,
    ),
  );
}

function* getUserPhoneSaga() {
  yield takeEvery(
    getDormantUserPhoneRequest.type,
    createFetchAction(
      getUserPhoneWithTempJwt,
      getDormantUserPhoneSuccess,
      getDormantUserPhoneFailure,
      undefined,
      failure,
    ),
  );
}

export default function* myInfoSaga() {
  yield all([
    refreshMyInfoSaga(),
    getMyInfoSaga(),
    setUserBriefSaga(),
    setUserSnsSaga(),
    updateThumbnailSaga(),
    updateNicknameSaga(),
    updatePasswordSaga(),
    toggleMarketingAgreementSaga(),
    getUserPhoneSaga(),
  ]);
}
