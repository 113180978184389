import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IWithdrawRequestPayload, WithdrawState } from 'store/types';
import { IResponse } from '@types';

const initialState: WithdrawState = {
  error: '',
};

export const withdrawSlice = createSlice({
  name: 'withdraw',
  initialState,
  reducers: {
    withdrawRequest: (
      state,
      action: PayloadAction<IWithdrawRequestPayload>,
    ) => {
      state.error = '';
    },
    withdrawSuccess: (state, action: PayloadAction<IResponse>) => {
      state.error = '';
    },
    withdrawFailure: (state, action: PayloadAction<Error | string>) => {
      state.error = action.payload;
    },
  },
});

export const { withdrawRequest, withdrawSuccess, withdrawFailure } =
  withdrawSlice.actions;

export default withdrawSlice.reducer;
