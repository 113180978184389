import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ExhibitionState,
  IExhibitionsRequestPayload,
  IGetExhibitionCampsRequestPayload,
} from 'store/types';
import { ICampExhibition, ICamp } from '@types';

const initialState: ExhibitionState = {
  isTouring: false,
  mainExhibitions: [],
  exhibitions: [],
  exhibition: {} as ICampExhibition,
  exhibitionCamps: [],
  MDPickExhibitions: [],
  campHasMore: true,
  campOffset: 0,
  hasMore: true,
  page: 1,
  error: '',
};

export const exhibitionSlice = createSlice({
  name: 'exhibition',
  initialState,
  reducers: {
    getMainExhibitionsRequest: (state, action: PayloadAction) => {
      state.mainExhibitions = [];
      state.error = '';
    },
    getMainExhibitionsSuccess: (
      state,
      action: PayloadAction<ICampExhibition[]>,
    ) => {
      state.mainExhibitions = action.payload;
      state.error = '';
    },
    getMainExhibitionsFailure: (
      state,
      action: PayloadAction<Error | string>,
    ) => {
      state.mainExhibitions = [];
      state.error = action.payload;
    },

    getExhibitionsRequest: (
      state,
      action: PayloadAction<IExhibitionsRequestPayload>,
    ) => {
      state.exhibitions = action.payload.page > 1 ? state.exhibitions : [];
      state.hasMore = true;
      state.error = '';
    },
    getExhibitionsSuccess: (
      state,
      action: PayloadAction<ICampExhibition[]>,
    ) => {
      state.exhibitions = state.exhibitions.concat(action.payload);
      state.hasMore = action.payload.length === 10;
      state.error = '';
    },
    getExhibitionsFailure: (state, action: PayloadAction<Error | string>) => {
      state.exhibitions = [];
      state.error = action.payload;
    },

    addPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },

    // 메인페이지 MD픽 캠핑장 관련.
    getMDPickExhibitionCampsRequest: (
      state,
      action: PayloadAction<IGetExhibitionCampsRequestPayload>,
    ) => {},
    getMDPickExhibitionCampsSuccess: (
      state,
      action: PayloadAction<ICamp[]>,
    ) => {
      state.MDPickExhibitions = action.payload;
      state.error = '';
    },
    getMDPickExhibitionCampsFailure: (
      state,
      action: PayloadAction<Error | string>,
    ) => {
      state.error = action.payload;
    },

    getExhibitionCampsRequest: (
      state,
      action: PayloadAction<IGetExhibitionCampsRequestPayload>,
    ) => {
      state.exhibitionCamps = action.payload.skip ? state.exhibitionCamps : [];
      state.campOffset = action.payload.skip;
      state.error = '';
    },
    getExhibitionCampsSuccess: (state, action: PayloadAction<ICamp[]>) => {
      if (action.payload.length)
        state.exhibitionCamps = state.exhibitionCamps.concat(action.payload);
      state.campHasMore = action.payload.length === 10;
      state.error = '';
    },
    getExhibitionCampsFailure: (
      state,
      action: PayloadAction<Error | string>,
    ) => {
      state.error = action.payload;
    },

    resetExhibitionCamps: state => {
      state.exhibitionCamps = [];
    },

    getExhibitionRequest: (state, action: PayloadAction<string>) => {
      state.exhibition = {} as ICampExhibition;
      state.error = '';
    },
    getExhibitionSuccess: (state, action: PayloadAction<ICampExhibition>) => {
      state.exhibition = action.payload;
      state.error = '';
    },
    getExhibitionFailure: (state, action: PayloadAction<Error | string>) => {
      state.exhibition = {} as ICampExhibition;
      state.error = action.payload;
    },

    addCampsOffset: (state, action: PayloadAction<number>) => {
      state.campOffset = action.payload;
    },

    onTouring: state => {
      state.isTouring = true;
    },

    offTouring: state => {
      state.isTouring = false;
    },
  },
});

export const {
  getMainExhibitionsRequest,
  getMainExhibitionsSuccess,
  getMainExhibitionsFailure,
  getExhibitionsRequest,
  getExhibitionsSuccess,
  getExhibitionsFailure,
  getExhibitionCampsRequest,
  getExhibitionCampsSuccess,
  getExhibitionCampsFailure,
  getMDPickExhibitionCampsRequest,
  getMDPickExhibitionCampsSuccess,
  getMDPickExhibitionCampsFailure,
  resetExhibitionCamps,
  getExhibitionRequest,
  getExhibitionSuccess,
  getExhibitionFailure,
  addPage,
  addCampsOffset,

  onTouring,
  offTouring,
} = exhibitionSlice.actions;

export default exhibitionSlice.reducer;
