export const isMobile = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent,
  );
};

export const getMobileAPPEnv = () => {
  const UA = navigator.userAgent.toLowerCase(); // userAgent 값 얻기

  if (UA.indexOf('android') > -1) {
    // 안드로이드
    return 'AOS';
  }

  if (
    UA.indexOf('iphone') > -1 ||
    UA.indexOf('ipad') > -1 ||
    UA.indexOf('ipod') > -1
  ) {
    // IOS
    return 'IOS';
  }

  // 아이폰, 안드로이드 외
  return '';
};

export const share = (name: string | undefined, url: string) => () => {
  if (isMobile()) {
    const shareDialog = document.querySelector('.share-dialog');
    if (navigator.share) {
      navigator
        .share({
          title: `[캠핏] ${name}`,
          url,
        })
        .then(() => {
          console.log('Thanks for sharing!');
        })
        .catch(console.error);
    } else {
      shareDialog?.classList.add('is-open');
    }
  } else {
    alert('링크가 복사되었습니다.');
  }
};
