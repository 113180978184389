import styled, { css } from 'styled-components';

interface Style {
  bottom?: boolean;
  isFull?: boolean;
  bg?: string;
  inModal?: boolean;
}

const ModalContainer = styled.div<Style>`
  position: fixed;
  top: 0;
  left: ${({ inModal }) =>
    inModal ? 'calc(50vw - 50px)' : 'calc(50vw - 50px)'};
  right: ${({ inModal }) =>
    inModal ? 'calc(50vw - 480px)' : 'calc(50vw - 480px)'};
  z-index: 2000;
  max-width: 530px;
  width: 100%;
  height: 100%;
  overflow: ${({ isFull }) => (isFull ? 'auto' : 'hidden')};
  background-color: ${({ isFull, theme, bg }) =>
    bg || (isFull ? theme.colors.bw100 : 'rgba(0, 0, 0, 0.5)')};
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  margin: 0 !important;

  &::-webkit-scrollbar {
    display: none;
  }

  ${({ inModal }) =>
    inModal
      ? css`
          @media screen and (max-width: 960px) {
            left: 0;
            right: 0;
          }
        `
      : css`
          @media screen and (max-width: 960px) {
            left: 50vw;
            transform: translate(-50%);
          }
        `};

  ${({ isFull, bottom }) =>
    !isFull &&
    `
   display: flex;
   justify-content: center;
   align-items: ${bottom ? 'flex-end' : 'center'};

  `}

  ${({ bottom }) =>
    bottom &&
    `
    height:100%;
    padding-top: calc(env(safe-area-inset-top));
    padding-top: constant(safe-area-inset-top);
  `}

   &.event {
    flex-direction: column;
  }
`;

export default ModalContainer;
