import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IArticle, IMainResponsePayload, IMainNewCamp } from '@types';
import { TMainState } from 'store/types';

const initialState: TMainState = {
  data: {
    numOfAlarms: undefined, // JWT 보내는 경우에만 존재
    mainBanners: undefined, // 메인 최상단 배너
    subBanners: [], // 메인 하단 광고 배너
    introBanners: [], // 슬라이드업 모달 광고 배너
    reviews: [], // 캠핑 log 목록
    article: {} as IArticle, // 매거진
    camps: [] as IMainNewCamp[], // 신규 캠핑장 목록
    campExhibitions: [], // 캠핑 기획전
  } as IMainResponsePayload,
  error: '',
};

export const mainSlice = createSlice({
  name: 'main',
  initialState,
  reducers: {
    mainRequest: state => {
      state.error = '';
    },
    mainSuccess: (state, action: PayloadAction<IMainResponsePayload>) => {
      state.data = action.payload;
    },
    mainFailure: (state, action: PayloadAction<Error | string>) => {
      state.error = action.payload;
    },
  },
});

export const { mainRequest, mainSuccess, mainFailure } = mainSlice.actions;
export default mainSlice.reducer;
