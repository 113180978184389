import { select, takeEvery, take, put, all } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/reducers';
import {
  searchRequest,
  searchSuccess,
  searchFailure,
  getCampAdRequest,
  getCampAdSuccess,
  getCampAdFailure,
  getCampsCountRequest,
  getCampsCountSuccess,
  getCampsCountFailure,
  searchCamps,
  addOffset,
  addEmptyCampsOffset,
  getEmptyCampsCntRequest,
  getEmptyCampsCntSuccess,
  getEmptyCampsCntFailure,
  getEmptyCampsRequest,
  getEmptyCampsSuccess,
  getEmptyCampsFailure,
  addAutoOffset,
} from 'store/reducers/search';
import { createFetchAction } from 'store/sagas/createFetchAction';
import {
  search,
  getCampCount,
  getEmptyCampsCnt,
  getEmptyCamps,
  getSearchAd,
} from 'api';
import {
  ISearchRequestPayload,
  IGetEmptyCampsRequestPayload,
} from 'store/types';
import { ILocationTable } from '@types';
import { customHistory } from 'App';

const LIMIT = 10;

const queryString = require('query-string');

const getLocations = (state: RootState) => state.filterReducer.locations;

function* goToSearchResults(
  action: PayloadAction<
    Partial<ISearchRequestPayload> &
      IGetEmptyCampsRequestPayload & { camp: string }
  >,
) {
  const locations: ILocationTable[] = yield select(getLocations);

  let queryObj = action.payload;

  let majorsArr: string[] = [];

  locations.forEach(l =>
    l.items.forEach(i => {
      if (i.city.name === queryObj.city) {
        const majors = queryObj.majors?.split(',');

        if (majors?.length) {
          majors.forEach(s => {
            i.subCity.forEach(sub => {
              if (sub.name === s) {
                majorsArr = [...majorsArr, sub.search];
              }
            });
          });
        }

        queryObj = {
          ...queryObj,
          city: i.city.search,
          majors: majorsArr.join(','),
        };
      }
    }),
  );

  const { isAll, camp, ...queryObjExceptIsAll } = queryObj;
  const query: string = queryString.stringify(
    { ...queryObjExceptIsAll, camp: camp?.trim() },
    {
      skipNull: true,
      skipEmptyString: true,
      arrayFormat: 'comma',
    },
  );

  if (window.location.pathname === '/search/geo') {
    customHistory.replace(`/search/geo?${query}`);
    return;
  }

  if (queryObj.isAll) {
    window.location.pathname === '/search'
      ? customHistory.push(`/search/result?${query}`)
      : window.location.replace(`/search/result?${query}`);
  } else {
    window.location.pathname === '/'
      ? customHistory.push(`/search/vacancy?${query}`)
      : window.location.replace(`/search/vacancy?${query}`);
  }
}

function* getMoreAutoCamps() {
  while (true) {
    const action: PayloadAction = yield take(addAutoOffset.type);
    const parsed = queryString.parse(window.location.search);
    const offset: number = yield select(
      (state: RootState) => state.searchReducer.offset,
    );

    yield put(
      searchRequest({
        search: parsed.camp,
        city: parsed.city,
        majors: parsed.majors,
        types: parsed.types,
        leisureTypes: parsed.leisureTypes,
        facilities: parsed.facilities,
        locationTypes: parsed.locationTypes,
        additionalFacilities: parsed.additionalFacilities,
        floorTypes: parsed.floorTypes,
        parkingTypes: parsed.parkingTypes,
        minBookableDays: parsed.minBookableDays,
        checkInTimestamp: parsed.checkInTimestamp,
        checkoutTimestamp: parsed.checkoutTimestamp,
        skip: offset,
        limit: LIMIT,
        sort: parsed.sort,
        onlinePayment: parsed.onlinePayment,
      }),
    );
  }
}

function* getMoreCamps() {
  while (true) {
    const action: PayloadAction<number> = yield take(addOffset.type);

    const parsed = queryString.parse(window.location.search);

    yield put(
      searchRequest({
        search: parsed.camp,
        city: parsed.city,
        majors: parsed.majors,
        types: parsed.types,
        leisureTypes: parsed.leisureTypes,
        facilities: parsed.facilities,
        locationTypes: parsed.locationTypes,
        additionalFacilities: parsed.additionalFacilities,
        floorTypes: parsed.floorTypes,
        parkingTypes: parsed.parkingTypes,
        minBookableDays: parsed.minBookableDays,
        checkInTimestamp: parsed.checkInTimestamp,
        checkoutTimestamp: parsed.checkoutTimestamp,
        skip: action.payload,
        limit: LIMIT,
        sort: parsed.sort,
      }),
    );
  }
}

function* getMoreAutoEmptyCamps() {
  while (true) {
    const action: PayloadAction = yield take(addEmptyCampsOffset.type);
    const parsed = queryString.parse(window.location.search);
    const offset: number = yield select(
      (state: RootState) => state.searchReducer.emptyCampsOffset,
    );

    yield put(
      getEmptyCampsRequest({
        search: parsed.camp,
        city: parsed.city,
        majors: parsed.majors,
        types: parsed.types,
        checkInTimestamp: parsed.checkInTimestamp,
        checkoutTimestamp: parsed.checkoutTimestamp,
        skip: offset,
        limit: LIMIT,
      }),
    );
  }
}

export default function* searchCampSaga() {
  yield all([
    takeEvery(
      getCampsCountRequest.type,
      createFetchAction(
        getCampCount,
        getCampsCountSuccess,
        getCampsCountFailure,
      ),
    ),
    takeEvery(searchCamps.type, goToSearchResults),
    getMoreCamps(),
    getMoreAutoCamps(),
    getMoreAutoEmptyCamps(),
    takeEvery(
      searchRequest.type,
      createFetchAction(search, searchSuccess, searchFailure),
    ),
    takeEvery(
      getCampAdRequest.type,
      createFetchAction(getSearchAd, getCampAdSuccess, getCampAdFailure),
    ),
    takeEvery(
      getEmptyCampsCntRequest.type,
      createFetchAction(
        getEmptyCampsCnt,
        getEmptyCampsCntSuccess,
        getEmptyCampsCntFailure,
      ),
    ),
    takeEvery(
      getEmptyCampsRequest.type,
      createFetchAction(
        getEmptyCamps,
        getEmptyCampsSuccess,
        getEmptyCampsFailure,
      ),
    ),
  ]);
}
