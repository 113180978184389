import { getSourcePath, removeSourcePath } from 'utils/sessionStorageUtils';
import { PayloadAction } from '@reduxjs/toolkit';
import { register } from 'api';
import { select, take, call, all, takeLatest, put } from 'redux-saga/effects';
import {
  registerFailure,
  registerRequest,
  registerSuccess,
  registerShopbyRequest,
  registerShopbySuccess,
  registerShopbyFailure,
} from 'store/reducers/register';
import { RootState } from 'store/reducers';
import { IRegisterResponse } from '@types';
import sendGtmDataLayer from 'utils/sendGtmDataLayer';
import { getShopbyAccessToken } from 'api/shopby';
import { createShopbyFetchAction, fetchApi } from './createFetchAction';
import { customHistory } from '../../App';

function* handleRegisterResult() {
  const {
    message,
    status,
    isEmailDuplicated,
    isUsernameDuplicated,
    jwt,
    user,
  }: IRegisterResponse = yield select(
    (state: RootState) => state.registerReducer.registerResponse,
  );

  if (status !== 'success') {
    if (!isEmailDuplicated && !isUsernameDuplicated) {
      if (message) {
        alert(message);
      } else {
        alert('회원가입에 실패했습니다.');
      }
    }
    return;
  }

  const { email, nickname, thumbnail } = user;

  // NOTE: 회원가입 성공 시점에 샵바이 회원가입
  yield put(registerShopbyRequest());
  localStorage.setItem('jwt', jwt);
  localStorage.setItem('email', email);
  localStorage.setItem('nickname', nickname);

  if (thumbnail && thumbnail.url) {
    localStorage.setItem('thumbnail', thumbnail.url);
  }

  sendGtmDataLayer({
    event: 'signup_complete',
  });

  // 회원가입 완료시 URL 변경을 위해 주석처리 / ywroh / 2022.03.17
  // yield put(goNextStep());
  const sourcePath = getSourcePath();

  if (sourcePath) {
    removeSourcePath();
    yield customHistory.push(sourcePath);
  } else {
    yield customHistory.push('/sign-up/complete');
  }
}

function* registerCamfitSaga() {
  while (true) {
    const action: PayloadAction<IRegisterResponse> = yield take(
      registerRequest.type,
    );

    yield call<any>(
      fetchApi,
      register,
      action.type,
      registerSuccess,
      registerFailure,
      action.payload,
    );
    yield handleRegisterResult();
  }
}

function* registerShopbySaga() {
  yield takeLatest(
    registerShopbyRequest.type,
    createShopbyFetchAction(
      getShopbyAccessToken,
      registerShopbySuccess,
      registerShopbyFailure,
      undefined,
      function onSuccess(payload: { data: string }) {
        localStorage.setItem('shopbyAccessToken', payload.data);
      },
    ),
  );
}

export default function* registerSaga() {
  yield all([registerCamfitSaga(), registerShopbySaga()]);
}
