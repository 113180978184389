import { PayloadAction } from '@reduxjs/toolkit';
import { takeEvery, all, take, put, select, call } from 'redux-saga/effects';
import {
  getMainExhibitionsFailure,
  getMainExhibitionsSuccess,
  getMainExhibitionsRequest,
  getExhibitionsSuccess,
  getExhibitionsFailure,
  getExhibitionsRequest,
  addPage,
  getExhibitionRequest,
  getExhibitionSuccess,
  getExhibitionFailure,
  getExhibitionCampsRequest,
  getExhibitionCampsSuccess,
  getExhibitionCampsFailure,
  addCampsOffset,
  getMDPickExhibitionCampsRequest,
  getMDPickExhibitionCampsSuccess,
  getMDPickExhibitionCampsFailure,
} from 'store/reducers/exhibition';
import sendGtmDataLayer from 'utils/sendGtmDataLayer';
import { createFetchAction } from 'store/sagas/createFetchAction';
import {
  getMainExhibitions,
  getExhibitions,
  findSingleExhibition,
  findExhibitionCamps,
} from 'api';
import { RootState } from 'store/reducers';
import { fetchApi } from './createFetchAction';

function* getExhibitionDetailSaga() {
  while (true) {
    const action: PayloadAction<string> = yield take(getExhibitionRequest.type);
    yield call<any>(
      fetchApi,
      findSingleExhibition,
      getExhibitionRequest.type,
      getExhibitionSuccess,
      getExhibitionFailure,
      action.payload,
    );

    yield put(
      getExhibitionCampsRequest({
        exhibitionCode: action.payload,
        skip: 0,
        limit: 10,
      }),
    );
  }
}

function* getMainExhibitionsSaga() {
  yield takeEvery(
    getMainExhibitionsRequest.type,
    createFetchAction(
      getMainExhibitions,
      getMainExhibitionsSuccess,
      getMainExhibitionsFailure,
    ),
  );
}

function* getExhibitionsSaga() {
  yield takeEvery(
    getExhibitionsRequest.type,
    createFetchAction(
      getExhibitions,
      getExhibitionsSuccess,
      getExhibitionsFailure,
    ),
  );
}

function* getMoreExhibitionsSaga() {
  while (true) {
    const action: PayloadAction<number> = yield take(addPage.type);

    yield put(
      getExhibitionsRequest({
        search: null,
        isForMain: null,
        sampleSize: 0,
        page: action.payload,
        pageSize: 10,
      }),
    );
  }
}

// 메인페이지 MD픽 캠핑장 관련 Saga.
function* getMDPickExhibitionCampsSaga() {
  yield takeEvery(
    getMDPickExhibitionCampsRequest,
    createFetchAction(
      findExhibitionCamps,
      getMDPickExhibitionCampsSuccess,
      getMDPickExhibitionCampsFailure,
    ),
  );
}

function* getExhibitionCampsSaga() {
  yield takeEvery(
    getExhibitionCampsRequest,
    createFetchAction(
      findExhibitionCamps,
      getExhibitionCampsSuccess,
      getExhibitionCampsFailure,
    ),
  );
}

function* getMoreExhibitionCampsSaga() {
  while (true) {
    const action: PayloadAction<number> = yield take(addCampsOffset.type);

    const { code } = yield select(
      (state: RootState) => state.exhibitionReducer.exhibition,
    );

    yield put(
      getExhibitionCampsRequest({
        exhibitionCode: code,
        skip: action.payload,
        limit: 10,
      }),
    );
  }
}

export default function* exhibitionsSaga() {
  yield all([
    getMainExhibitionsSaga(),
    getExhibitionsSaga(),
    getMoreExhibitionsSaga(),
    getExhibitionCampsSaga(),
    getMoreExhibitionCampsSaga(),
    getExhibitionDetailSaga(),
    getMDPickExhibitionCampsSaga(),
  ]);
}
