import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = (props: any) => {
  const { component: Component, location, ...rest } = props;
  const isLoggedIn = !!localStorage.getItem('jwt');

  return (
    <Route
      {...rest}
      render={routeProps => {
        return isLoggedIn ? (
          <Component {...routeProps} />
        ) : (
          <Redirect to={{ pathname: '/login', state: { from: location } }} />
        );
      }}
    />
  );
};

export default PrivateRoute;
