import {
  takeEvery,
  select,
  all,
  take,
  call,
  put,
  actionChannel,
  ActionPattern,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects';
import {
  getNewCampListRequest,
  getNewCampListSuccess,
  getNewCampListFailure,
  getCampRequest,
  getCampSuccess,
  getCampFailure,
} from 'store/reducers/camp';
import {
  getZoneListRequest,
  getZoneListSuccess,
  getZoneListFailure,
  getZoneCntRequest,
  getZoneCntSuccess,
  getZoneCntFailure,
} from 'store/reducers/zone';
import {
  getCampDetail,
  getZonesByCamp,
  countZonesByCamp,
  findHolidays,
  getCampAnnounces,
  getCampAnnouncesCnt,
  getMainNewCampList,
} from 'api';
import {
  getHolidaysRequest,
  getHolidaysSuccess,
  getHolidaysFailure,
} from 'store/reducers/calendar';
import { changeDateFormat } from 'utils/calculateDate';
import { IRecentCamp, ICampDetail } from '@types';
import { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/reducers';
import {
  getCampAnnouncesByCampFailure,
  getCampAnnouncesByCampRequest,
  getCampAnnouncesByCampSuccess,
  getCampAnnouncesCntFailure,
  getCampAnnouncesCntRequest,
  getCampAnnouncesCntSuccess,
} from 'store/reducers/campAnnounces';
import { createFetchAction, fetchApi } from './createFetchAction';
import { PeopleCnt, ReservationDate } from '../types';
import { failure } from './failure';

export const ZONELIMIT = 4;

function saveRecentCamp(camp: ICampDetail) {
  const recents: string | null = localStorage.getItem('recents');

  let camps: IRecentCamp[] = [];

  if (typeof recents === 'string') {
    camps = JSON.parse(recents);
  }

  const now = new Date();
  const sCamp = {
    id: camp.id,
    thumbnail: camp.thumbnail,
    name: camp.name,
    date: changeDateFormat(now, 'yyyy.MM.dd'),
    city: camp.city,
    major: camp.major,
  } as IRecentCamp;

  if (!camps.find(c => c.id === sCamp.id)) {
    camps = [sCamp, ...camps];
  } else {
    camps = [sCamp, ...camps.filter(c => c.id !== sCamp.id)];
  }

  if (camps.length > 10) {
    camps = camps.slice(0, 10);
  }
  localStorage.setItem('recents', JSON.stringify(camps));
}

export function* fetchCamp() {
  const subChannel: ActionPattern = yield actionChannel(getCampRequest.type);
  while (true) {
    const action: PayloadAction<{ id: string; zoneId?: string }> = yield take(
      subChannel,
    );
    yield call<any>(
      fetchApi,
      getCampDetail,
      action.type,
      getCampSuccess,
      getCampFailure,
      action.payload,
    );

    yield call(getCampAnnouncesByCamp, action.payload.id);

    const camp: ICampDetail = yield select(
      (state: RootState) => state.campReducer.camp,
    );

    if (Object.keys(camp).length) {
      const isCampsSaving: string | null =
        localStorage.getItem('isCampsSaving');
      if (isCampsSaving === 'true') yield call(saveRecentCamp, camp);

      yield call(getZones, camp.id, ZONELIMIT, 0);

      yield put(getZoneCntRequest());
      yield call<any>(
        fetchApi,
        countZonesByCamp,
        getZoneCntRequest.type,
        getZoneCntSuccess,
        getZoneCntFailure,
        camp.id,
      );
    }
  }
}

function* getCampAnnouncesByCamp(campId: string) {
  const params = {
    search: '',
    campId,
    page: 1,
    pageSize: 3,
  };

  yield put(getCampAnnouncesByCampRequest(params));
  yield call<any>(
    fetchApi,
    getCampAnnounces,
    getCampAnnouncesByCampRequest.type,
    getCampAnnouncesByCampSuccess,
    getCampAnnouncesByCampFailure,
    params,
  );

  yield put(getCampAnnouncesCntRequest({ search: '', campId }));
  yield call<any>(
    fetchApi,
    getCampAnnouncesCnt,
    getCampAnnouncesCntRequest.type,
    getCampAnnouncesCntSuccess,
    getCampAnnouncesCntFailure,
    { search: '', campId },
  );
}

export function* getZones(id: string, limit: number, skip: number) {
  const date: ReservationDate = yield select(
    (state: RootState) => state.reservationReducer.date,
  );
  const peopleCnt: PeopleCnt = yield select(
    (state: RootState) => state.reservationReducer.peopleCnt,
  );

  yield put(
    getZoneListRequest({
      id,
      adult: peopleCnt.adultCnt,
      teen: peopleCnt.teenCnt,
      child: peopleCnt.childCnt,
      startTimestamp: date.start ? date.start.getTime() : 0,
      endTimestamp: date.end ? date.end.getTime() : 0,
      limit,
      skip,
    }),
  );
  yield call<any>(
    fetchApi,
    getZonesByCamp,
    getZoneListRequest.type,
    getZoneListSuccess,
    getZoneListFailure,
    {
      id,
      adult: peopleCnt.adultCnt,
      teen: peopleCnt.teenCnt,
      child: peopleCnt.childCnt,
      startTimestamp: date.start ? date.start.getTime() : 0,
      endTimestamp: date.end ? date.end.getTime() : 0,
      limit,
      skip,
    },
  );
}

// Main
function* getNewCampListSaga() {
  yield takeLeading(
    getNewCampListRequest.type,
    createFetchAction(
      getMainNewCampList,
      getNewCampListSuccess,
      getNewCampListFailure,
      undefined,
      failure,
    ),
  );
}
export function* campSaga() {
  yield all([
    takeEvery(
      getHolidaysRequest.type,
      createFetchAction(findHolidays, getHolidaysSuccess, getHolidaysFailure),
    ),
    fetchCamp(),
    getNewCampListSaga(),
  ]);
}
