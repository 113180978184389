import { AxiosError } from 'axios';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ZoneState,
  IGetZoneListRequestPayload,
  IGetZoneRequestPayload,
  IGetAddOptsRequestPayload,
} from 'store/types';
import { IZone, IZoneDetail } from '@types';

const initialState: ZoneState = {
  zone: {} as IZoneDetail,
  additionalOpts: [],
  zoneList: [],
  zoneCnt: 0,
  error: null,
  isMoreBtnClicked: false,
  isLoadedAll: false,
};

export const zoneSlice = createSlice({
  name: 'zone',
  initialState,
  reducers: {
    startFetchingZone: (state, action: PayloadAction<string>) => {
      state;
    },

    getZoneRequest: state => {
      state.zone = {} as IZoneDetail;
      state.error = null;
    },
    getZoneSuccess: (state, action: PayloadAction<IZoneDetail>) => {
      state.zone = action.payload;
      state.error = null;
    },
    getZoneFailure: (state, action: PayloadAction<AxiosError>) => {
      state.zone = {} as IZoneDetail;
      state.error = action.payload;
    },

    getAdditionalOptsRequest: state => {
      state.additionalOpts = [];
      state.error = null;
    },
    getAdditionalOptsSuccess: (state, action) => {
      state.additionalOpts = action.payload;
      state.error = null;
    },
    getAdditionalOptsFailure: (state, action) => {
      state.additionalOpts = [];
      state.error = action.payload;
    },

    getZoneListRequest: (
      state,
      action: PayloadAction<IGetZoneListRequestPayload>,
    ) => {
      state.zoneList = action.payload.skip ? state.zoneList : [];
      state.isLoadedAll = action.payload.skip ? state.isLoadedAll : false;
      state.isMoreBtnClicked = action.payload.skip
        ? state.isMoreBtnClicked
        : false;
      state.error = null;
    },
    getZoneListSuccess: (state, action: PayloadAction<IZone[]>) => {
      state.zoneList = state.zoneList.concat(action.payload);
      state.error = null;
    },
    getZoneListFailure: (state, action: PayloadAction<AxiosError>) => {
      state.zoneList = [];
      state.error = action.payload;
    },

    getZoneCntRequest: state => {
      state.zoneCnt = 0;
      state.error = null;
    },
    getZoneCntSuccess: (state, action: PayloadAction<number>) => {
      state.zoneCnt = action.payload;
      state.error = null;
    },
    getZoneCntFailure: (state, action: PayloadAction<AxiosError>) => {
      state.zoneCnt = 0;
      state.error = action.payload;
    },

    showMoreZoneList: (state, action: PayloadAction<string>) => {
      state.isMoreBtnClicked = !state.isMoreBtnClicked;
    },

    checkLoadStatus: state => {
      state.isLoadedAll = true;
    },
  },
});

export const {
  startFetchingZone,
  getZoneRequest,
  getZoneSuccess,
  getZoneFailure,
  getAdditionalOptsRequest,
  getAdditionalOptsSuccess,
  getAdditionalOptsFailure,
  getZoneListRequest,
  getZoneListSuccess,
  getZoneListFailure,
  getZoneCntRequest,
  getZoneCntSuccess,
  getZoneCntFailure,
  showMoreZoneList,
  checkLoadStatus,
} = zoneSlice.actions;

export default zoneSlice.reducer;
