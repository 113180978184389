import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  SearchState,
  ISearchRequestPayload,
  IGetEmptyCampsRequestPayload,
  ICampAdRequestPayload,
} from 'store/types';
import { ICamp, ICampAdvertisement } from '@types';

const initialState: SearchState = {
  campList: [],
  campAdList: [],
  campsCnt: 0,
  error: '',
  hasMore: true,
  offset: 0,
  popPath: '',
  emptyCamps: [],
  emptyCampsOffset: 0,
  hasMoreEmptyCamps: true,
  emptyCampsCnt: 0,
  sortedType: 'recommendDesc',
};

export const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    searchRequest: (state, action: PayloadAction<ISearchRequestPayload>) => {
      action.payload.skip ? state.campList : (state.campList = []);
      state.error = '';
      state.sortedType =
        action.payload.sort === undefined
          ? 'recommendDesc'
          : action.payload.sort;
    },

    searchSuccess: (state, action: PayloadAction<ICamp[]>) => {
      if (action.payload.length)
        state.campList = state.campList.concat(action.payload);
      state.hasMore = action.payload.length === 10;
      state.error = '';
    },

    searchFailure: (state, action: PayloadAction<Error | string>) => {
      state.error = action.payload;
    },

    getCampAdRequest: (state, action: PayloadAction<ICampAdRequestPayload>) => {
      state.error = '';
      state.sortedType =
        action.payload.sort === undefined
          ? 'recommendDesc'
          : action.payload.sort;
    },

    getCampAdSuccess: (state, action: PayloadAction<ICampAdvertisement[]>) => {
      state.campAdList = action.payload;
      state.error = '';
    },

    getCampAdFailure: (state, action: PayloadAction<Error | string>) => {
      state.error = action.payload;
    },

    searchCamps: (
      state,
      action: PayloadAction<
        Partial<ISearchRequestPayload> | IGetEmptyCampsRequestPayload
      >,
    ) => {
      state.offset = 0;
      state.emptyCampsOffset = 0;
      state.hasMore = true;
    },

    resetSearchCampsState: state => {
      state.offset = 0;
      state.emptyCampsOffset = 0;
      state.hasMore = true;
    },

    getCampsCountRequest: (
      state,
      action: PayloadAction<ISearchRequestPayload>,
    ) => {
      state.campsCnt = 0;
      state.error = '';
    },

    getCampsCountSuccess: (state, action: PayloadAction<number>) => {
      state.campsCnt = action.payload;
      state.error = '';
    },

    getCampsCountFailure: (state, action: PayloadAction<Error | string>) => {
      state.campsCnt = 0;
      state.error = action.payload;
    },

    addOffset: (state, action: PayloadAction<number>) => {
      state.offset = action.payload;
    },
    addAutoOffset: state => {
      state.offset += 10;
    },

    checkPopPath: (state, action: PayloadAction<string>) => {
      state.popPath = action.payload;
    },

    getEmptyCampsRequest: (
      state,
      action: PayloadAction<IGetEmptyCampsRequestPayload>,
    ) => {
      action.payload.skip ? state.emptyCamps : (state.emptyCamps = []);
      state.error = '';
    },

    getEmptyCampsSuccess: (state, action: PayloadAction<ICamp[]>) => {
      if (action.payload.length)
        state.emptyCamps = state.emptyCamps.concat(action.payload);
      state.hasMoreEmptyCamps = action.payload.length === 10;
      state.error = '';
    },

    getEmptyCampsFailure: (state, action: PayloadAction<Error | string>) => {
      state.error = action.payload;
    },

    addEmptyCampsOffset: state => {
      state.emptyCampsOffset += 10;
    },

    getEmptyCampsCntRequest: (state, action) => {
      state.emptyCampsCnt = 0;
      state.error = '';
    },

    getEmptyCampsCntSuccess: (state, action: PayloadAction<number>) => {
      state.emptyCampsCnt = action.payload;
      state.error = '';
    },

    getEmptyCampsCntFailure: (state, action: PayloadAction<Error | string>) => {
      state.emptyCampsCnt = 0;
      state.error = action.payload;
    },
  },
});

export const {
  searchRequest,
  searchSuccess,
  searchFailure,
  getCampAdRequest,
  getCampAdSuccess,
  getCampAdFailure,
  searchCamps,
  resetSearchCampsState,
  getCampsCountRequest,
  getCampsCountSuccess,
  getCampsCountFailure,
  addOffset,
  addAutoOffset,
  checkPopPath,
  getEmptyCampsRequest,
  getEmptyCampsSuccess,
  getEmptyCampsFailure,
  getEmptyCampsCntRequest,
  getEmptyCampsCntSuccess,
  getEmptyCampsCntFailure,
  addEmptyCampsOffset,
} = searchSlice.actions;

export default searchSlice.reducer;
