import {
  select,
  take,
  call,
  takeEvery,
  ActionPattern,
  actionChannel,
  takeLatest,
  all,
  put,
} from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  calculateBookingFailure,
  calculateBookingRequest,
  calculateBookingSuccess,
  completeSelection,
  completeSelectionInZone,
  getBookingInfoFailure,
  getBookingInfoRequest,
  getBookingInfoSuccess,
  getRegisterdAdditionalUserInfoRequest,
  getRegisterdAdditionalUserInfoSuccess,
  getRegisterdAdditionalUserInfoFailure,
  reserve,
  setSelfCheckinStatus,
  submitSelfCheckinRequest,
  submitSelfCheckinSuccess,
  submitSelfCheckinFailure,
  cancelPreBookingRequest,
  cancelPreBookingSuccess,
  cancelPreBookingFailure,
  getPreBookingInfoRequest,
  getPreBookingInfoSuccess,
  getPreBookingInfoFailure,
} from 'store/reducers/reservation';
import { RootState } from 'store/reducers';
import {
  calculateBooking,
  cancelPreBooking,
  getBookingDetail,
  getRegisterdAdditionalUserInfo,
  submitSelfCheckin,
} from 'api';
import { toast } from 'react-toastify';
import { IReservePayload } from 'store/types';
import { setSourcePath } from 'utils/sessionStorageUtils';
import { IBookingResultFailureResponse } from '@types';
import { getZones, ZONELIMIT } from './camp';
import { getZone, getSites } from './fetchZoneDetail';
import { createFetchAction } from './createFetchAction';
import { customHistory } from '../../App';
import { failure } from './failure';

function* updateZones() {
  const { id } = yield select((state: RootState) => state.campReducer.camp);
  yield call(getZones, id, ZONELIMIT, 0);
}

function* updateZoneDetail() {
  const subChannel: ActionPattern = yield actionChannel(
    completeSelectionInZone.type,
  );

  while (true) {
    const action: PayloadAction<string> = yield take(subChannel);
    yield call(getZone, action.payload);
    yield call(getSites, action.payload);
  }
}

function* getRegisterdAdditionalUserInfoSaga() {
  yield takeLatest(
    getRegisterdAdditionalUserInfoRequest.type,
    createFetchAction(
      getRegisterdAdditionalUserInfo,
      getRegisterdAdditionalUserInfoSuccess,
      getRegisterdAdditionalUserInfoFailure,
    ),
  );
}

function* calculateBookingSaga() {
  yield takeLatest(
    calculateBookingRequest.type,
    createFetchAction(
      calculateBooking,
      calculateBookingSuccess,
      calculateBookingFailure,
      function* success() {
        yield customHistory.push('/reservation/payment');
      },
      failure,
    ),
  );
}

function* completeEnteringBookingInfo() {
  while (true) {
    const action: PayloadAction<IReservePayload> = yield take(reserve.type);
    const { peopleCnt, date, site } = yield select(
      (state: RootState) => state.reservationReducer,
    );

    const { adultCnt, teenCnt, childCnt } = peopleCnt;
    const { start, end } = date;

    const { hasCampingCar, hasTrailer, services, numOfCars, petInfo } =
      action.payload;

    yield put(
      calculateBookingRequest({
        siteId: site.id,
        checkInDate: {
          year: start.getFullYear(),
          month: start.getMonth() + 1,
          day: start.getDate(),
        },
        checkoutDate: {
          year: end.getFullYear(),
          month: end.getMonth() + 1,
          day: end.getDate(),
        },
        numOfAdults: adultCnt,
        numOfTeens: teenCnt,
        numOfChildren: childCnt,
        numOfCars,
        services,
        hasTrailer,
        hasCampingCar,
        pets: petInfo,
      }),
    );
  }
}

export function* selectDateAndPeopleCntSaga() {
  yield takeEvery(completeSelection.type, updateZones);
  yield updateZoneDetail();
}

export function* getBookingResult() {
  yield takeEvery(
    getBookingInfoRequest.type,
    createFetchAction(
      getBookingDetail,
      getBookingInfoSuccess,
      getBookingInfoFailure,
      undefined,
      function* fail(res: IBookingResultFailureResponse) {
        if (res.message) {
          yield alert(res.message);

          if (res.needLogin) {
            yield setSourcePath(customHistory.location.pathname);
            yield customHistory.push('/login');
          } else {
            yield customHistory.push('/');
          }

          return;
        }

        yield customHistory.push('/');
      },
    ),
  );
}

export function* submitSelfCheckinSaga() {
  yield takeEvery(
    submitSelfCheckinRequest.type,
    createFetchAction(
      submitSelfCheckin,
      submitSelfCheckinSuccess,
      submitSelfCheckinFailure,
      function* success() {
        yield put(setSelfCheckinStatus('success'));
        yield toast.success('체크인 완료', { position: 'top-center' });
        yield window.scrollTo(0, 0);
      },
    ),
  );
}

export function* cancelPreBookingSaga() {
  yield takeEvery(
    cancelPreBookingRequest.type,
    createFetchAction(
      cancelPreBooking,
      cancelPreBookingSuccess,
      cancelPreBookingFailure,
    ),
  );
}

// 결제대기 상태에서 재결제 시도시 동작.
export function* getPreBookingInfoSaga() {
  yield takeEvery(
    getPreBookingInfoRequest.type,
    createFetchAction(
      getBookingDetail,
      getPreBookingInfoSuccess,
      getPreBookingInfoFailure,
      function* success() {
        yield customHistory.push('/reservation/payment?isRepayment=true');
      },
    ),
  );
}

export function* reservationSaga() {
  yield all([
    getRegisterdAdditionalUserInfoSaga(),
    calculateBookingSaga(),
    completeEnteringBookingInfo(),
    getBookingResult(),
    submitSelfCheckinSaga(),
    cancelPreBookingSaga(),
    getPreBookingInfoSaga(),
  ]);
}
