import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';
import 'static/styles/fonts/font.css';

const GlobalStyle = createGlobalStyle`
${reset};


html {
  font-size: 62.5%;
}

*{
    margin: 0px;
    padding: 0px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    resize: none;
    outline: 0;
    -webkit-tap-highlight-color: rgba(255,255,255,0);
}

body{
  font-size: 1.4rem;
  font-family: 'SpoqaHanSans';
  font-weight: 400;
  color: #050606;
  line-height: 1.4;
  -ms-overflow-style: none; // IE에서 스크롤바 감춤
  &::-webkit-scrollbar {
    display: none; // 윈도우 크롬 등
  }
}

ol,
ul,
li {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }

  &.noClick{
    cursor: auto;
  }
}

button{
  font-family: 'SpoqaHanSans';
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
  background: none;
}

input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  background-image: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border-radius: 0px;
}

input,
textarea,
select {
  font-family: 'SpoqaHanSans';
  font-weight: 400;
  font-size: 1.4rem;
  outline: none;
  line-height: 140%;
}

* {
  font-family: 'SpoqaHanSans';
}

input::placeholder, textarea::placeholder {
  font-family: 'SpoqaHanSans';
}

input[type='number'] {
  -moz-appearance: textfield;

  &::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

input:focus,
button:focus,
select:focus,
textarea:focus {
  outline: none;
}

button{
  border: none;
  outline: 0;
  cursor: pointer;
}

.MuiContainer-root {
  max-width: 530px;
  height: fit-content;
}

.MuiButton-root,
.MuiTypography-body1,
.MuiTypography-subtitle2,
.MuiInputBase-root {
  font-family: 'SpoqaHanSans';
  font-weight: 400;
  font-size: 1.4rem;
}

.MuiCheckbox-root {
  color: #767A78;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset
}

::-webkit-scrollbar {
  width: 0.3vw; /* 세로축 스크롤바 길이 */
  height: 0.3vh; /* 가로축 스크롤바 길이 */
}
iframe {
  border: 0;
}

span.swiper-pagination-bullet {
  width: 5px;
  height: 5px;
  background: #FFFFFF;
  opacity: 0.7;

  &.swiper-pagination-bullet-active {
    opacity: 1;
  }
}

/* variant */
.typography-largeTitle {
  font-size: 3rem;
  line-height: 137%;
  letter-spacing: 0.04rem;
}
.typography-title1 {
  font-size: 2.8rem;
  line-height: 136%;
  letter-spacing: 0.036rem;
}
.typography-title2 {
  font-size: 2.2rem;
  line-height: 145%;
  letter-spacing: 0.035rem;
}
.typography-title3 {
  font-size: 2rem;
  line-height: 150%;
  letter-spacing: 0rem;
}
.typography-headline {
  font-size: 1.7rem;
  line-height: 153%;
  letter-spacing: -0.04rem;
}
.typography-callout {
  font-size: 1.6rem;
  line-height: 131%;
  letter-spacing: -0.03rem;
}
.typography-callout2 {
  font-size: 1.4rem;
  line-height: 150%;
  letter-spacing: -0.02rem;
}
.typography-body {
  font-size: 1.6rem;
  line-height: 169%;
  letter-spacing: -0.03rem;
}
.typography-body2 {
  font-size: 1.4rem;
  line-height: 179%;
  letter-spacing: -0.03rem;
}
.typography-subheadline {
  font-size: 1.5rem;
  line-height: 140%;
  letter-spacing: -0.03rem;
}
.typography-footnote {
  font-size: 1.3rem;
  line-height: 162%;
  letter-spacing: -0.008rem;
}
.typography-caption1 {
  font-size: 1.2rem;
  line-height: 150%;
  letter-spacing: -0.008rem;
}
.typography-caption2 {
  font-size: 1.1rem;
  line-height: 145%;
  letter-spacing: -0.008rem;
}

/* number-variant */
.typography-number20 {
  font-size: 2rem;
  line-height: 150%;
  letter-spacing: 0.006rem;
}
.typography-number17 {
  font-size: 1.7rem;
  line-height: 147%;
  letter-spacing: 0.004rem;
}
.typography-number15 {
  font-size: 1.5rem;
  line-height: 153%;
  letter-spacing: 0.004rem;
}

.typography-number14 {
  font-size: 1.4rem;
  line-height: 150%;
  letter-spacing: 0.004rem;
}

.typography-number13 {
  font-size: 1.3rem;
  line-height: 154%;
  letter-spacing: 0.003rem;
}

.typography-number12 {
  font-size: 1.2rem;
  line-height: 150%;
  letter-spacing: 0.002rem;
}

/* weight */
.typography-regular {
  font-weight: 400;
}

.typography-medium {
  font-weight: 500;
}

.typography-bold {
  font-weight: 700;
}

.hidden {
  display: none;
}
`;

export default GlobalStyle;
