import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  TV2CouponState,
  IV2CouponListRequestPayload,
  TCouponListName,
  TPaging,
  IErrorPayloadWithKey,
  IV2IssueCouponPayload,
} from 'store/types';
import { ICouponResonse, IV2Coupon, IV2MyCoupon } from '@types';

const DEFAULT_LIMIT = 10;

const initialBasicState = {
  dataList: [],
  paging: {
    hasMore: true,
    skip: 0,
    limit: DEFAULT_LIMIT,
    totalCount: 0,
  } as TPaging,
  error: '',
  isNeedCertification: false,
};

const initialDownloadableState = {
  dataList: [],
  paging: {
    hasMore: true,
    skip: 0,
    limit: DEFAULT_LIMIT,
    totalCount: 0,
  } as TPaging,
  error: '',
  download: {
    isDownload: false,
    couponId: '',
  },
  isNeedCertification: false,
};
// NOTE: 사용되는 페이지나 카테고리(key)를 명시하기 위한 initialState
const initialState: TV2CouponState = {
  camp: initialBasicState,
  couponBook: initialDownloadableState,
  my: initialDownloadableState,
  event: initialDownloadableState,
};

export const v2CouponSlice = createSlice({
  name: 'v2Coupon',
  initialState,
  reducers: {
    resetCouponList: (
      state,
      action: PayloadAction<{
        key: TCouponListName;
      }>,
    ) => {
      const { key } = action.payload;
      state[key] = {
        ...state[key],
        dataList: [],
        paging: {
          hasMore: true,
          skip: 0,
          limit: DEFAULT_LIMIT,
          totalCount: 0,
        },
        error: '',
      };
    },

    resetDownloadStatus: (
      state,
      action: PayloadAction<{
        key: TCouponListName;
      }>,
    ) => {
      const { key } = action.payload;
      state[key].download = {
        isDownload: false,
        couponId: '',
      };
    },

    resetError: (
      state,
      action: PayloadAction<{
        key: TCouponListName;
      }>,
    ) => {
      const { key } = action.payload;
      state[key].error = '';
    },

    setCouponTotalCount: (
      state,
      action: PayloadAction<{
        key: TCouponListName;
        count: number;
      }>,
    ) => {
      const { key, count } = action.payload;
      state[key].paging.totalCount = count || 0;
    },

    getV2CouponListRequest: (
      state,
      action: PayloadAction<{
        key: TCouponListName;
        params: IV2CouponListRequestPayload;
        forceUpdate?: boolean;
      }>,
    ) => {
      const { key, params } = action.payload;
      state[key].error = '';
      state[key].paging.limit = params.limit || DEFAULT_LIMIT;
    },
    getV2CouponListSuccess: (
      state,
      action: PayloadAction<{
        key: TCouponListName;
        data: IV2Coupon[];
      }>,
    ) => {
      const { key, data } = action.payload;
      state[key].dataList = [...state[key].dataList, ...data] as IV2Coupon[];

      if (key === 'couponBook') {
        state[key].paging.hasMore = data.length === DEFAULT_LIMIT;
      } else {
        state[key].paging.hasMore =
          state[key].dataList.length < state[key].paging.totalCount;
      }

      state[key].paging.skip += data.length;
    },
    getV2CouponListFailure: (
      state,
      action: PayloadAction<IErrorPayloadWithKey>,
    ) => {
      const { key, error } = action.payload;
      state[key].error = error;
    },
    getV2CouponListMore: (
      state,
      action: PayloadAction<{
        key: TCouponListName;
        params: { campId?: string };
      }>,
    ) => {},

    getMyCouponsCountRequest: (state, action: PayloadAction) => {
      // state.my.paging.totalCount = 0;
      // state.my.error = '';
      state.my = initialDownloadableState;
    },
    getMyCouponsCountSuccess: (state, action: PayloadAction<number>) => {
      state.my.paging.totalCount = action.payload;
      state.my.error = '';
    },
    getMyCouponsCountFailure: (
      state,
      action: PayloadAction<Error | string>,
    ) => {
      state.my.paging.totalCount = 0;
      state.my.error = action.payload;
    },

    getMyCouponListRequest: (
      state,
      action: PayloadAction<{
        skip: number;
        limit: number;
      }>,
    ) => {
      action.payload.skip ? state.my.dataList : (state.my.dataList = []);
      state.my.paging.limit = action.payload.limit;
      state.my.error = '';
    },
    getMyCouponListSuccess: (state, action: PayloadAction<IV2MyCoupon[]>) => {
      state.my.dataList = [
        ...state.my.dataList,
        ...action.payload,
      ] as IV2MyCoupon[];

      if (state.my.paging.totalCount) {
        state.my.paging.hasMore =
          state.my.dataList.length < state.my.paging.totalCount;
      }
      state.my.paging.skip += state.my.paging.limit;
      state.my.error = '';
    },
    getMyCouponListFailure: (state, action: PayloadAction<Error | string>) => {
      state.my.error = action.payload;
    },

    downloadV2CouponRequest: (
      state,
      action: PayloadAction<{
        key: TCouponListName;
        params: IV2IssueCouponPayload;
        successFunc: (data: ICouponResonse) => void;
      }>,
    ) => {
      const { key } = action.payload;
      state[key].error = '';
    },
    downloadV2CouponSuccess: (
      state,
      action: PayloadAction<{
        key: TCouponListName;
        data: ICouponResonse;
      }>,
    ) => {
      const { key, data } = action.payload;
      const { couponId: downloadedCouponId } = data;

      const coupons = state[key].dataList as IV2Coupon[];
      const coupon: IV2Coupon | undefined = coupons.find(
        (coupon: IV2Coupon) => coupon.id === downloadedCouponId,
      );

      if (!coupon) {
        state[key].error =
          '다운로드에 실패하였습니다. 쿠폰을 다시 확인해주세요.';
        return;
      }

      state[key].download = {
        isDownload: true,
        couponId: downloadedCouponId,
      };

      coupon.numOfUserIssued += 1;
      coupon.numOfDailyIssued += 1;
      coupon.numOfIssued += 1;
      coupon.isIssued = true;
    },
    downloadV2CouponFailure: (
      state,
      action: PayloadAction<IErrorPayloadWithKey>,
    ) => {
      const { key, error } = action.payload;
      state[key].error = error;
    },

    issueV2CouponRequest: (
      state,
      action: PayloadAction<{
        key: TCouponListName;
        params: IV2IssueCouponPayload;
      }>,
    ) => {
      const { key } = action.payload;
      state[key].error = '';
    },
    issueV2CouponSuccess: (
      state,
      action: PayloadAction<{
        key: TCouponListName;
        data: ICouponResonse;
      }>,
    ) => {
      const { key, data } = action.payload;
      const { couponId: registeredCouponId } = data;

      state[key].download = {
        isDownload: true,
        couponId: registeredCouponId,
      };

      state[key].error = '';
    },
    issueV2CouponFailure: (
      state,
      action: PayloadAction<IErrorPayloadWithKey>,
    ) => {
      const { key, error } = action.payload;
      state[key].error = error;
    },

    downloadCamfitCouponRequest: (
      state,
      action: PayloadAction<{
        key: TCouponListName;
        params: IV2IssueCouponPayload;
        successFunc: (data: ICouponResonse) => void;
      }>,
    ) => {
      const { key } = action.payload;
      state[key].error = '';
    },
    downloadCamfitCouponSuccess: (
      state,
      action: PayloadAction<{
        key: TCouponListName;
        data: ICouponResonse;
      }>,
    ) => {
      const { key, data } = action.payload;
      const { couponId: downloadedCouponId } = data;

      const coupons = state[key].dataList as IV2Coupon[];
      const coupon: IV2Coupon | undefined = coupons.find(
        (coupon: IV2Coupon) => coupon.id === downloadedCouponId,
      );

      if (!coupon) {
        state[key].error =
          '다운로드에 실패하였습니다. 쿠폰을 다시 확인해주세요.';
        return;
      }

      state[key].download = {
        isDownload: true,
        couponId: downloadedCouponId,
      };

      coupon.numOfUserIssued += 1;
      coupon.numOfDailyIssued += 1;
      coupon.numOfIssued += 1;
      coupon.isIssued = true;

      state[key].isNeedCertification = false;
    },
    downloadCamfitCouponFailure: (
      state,
      action: PayloadAction<{
        key: string;
        error: Error | string;
      }>,
    ) => {
      const { key, error } = action.payload;

      if (typeof error === 'string' && error.includes('본인인증')) {
        state[key].isNeedCertification = true;
      }

      state[key].error = error;
    },

    setNeedCertification: (
      state,
      action: PayloadAction<{ key: TCouponListName; value: boolean }>,
    ) => {
      const { key, value } = action.payload;
      state[key].isNeedCertification = value;
    },

    getCouponDetailRequest: (
      state,
      action: PayloadAction<{
        key: TCouponListName;
        couponId: string;
      }>,
    ) => {
      const { key } = action.payload;
      state[key].error = '';
    },
    getCouponDetailSuccess: (
      state,
      action: PayloadAction<{
        key: TCouponListName;
        data: {
          data: IV2Coupon;
        };
      }>,
    ) => {
      const { key, data } = action.payload;
      const { data: couponeDetail } = data;

      state[key].dataList = [
        ...state[key].dataList,
        couponeDetail,
      ] as IV2Coupon[];
    },
    getCouponDetailFailure: (
      state,
      action: PayloadAction<IErrorPayloadWithKey>,
    ) => {
      const { key, error } = action.payload;
      state[key].error = error;
    },
  },
});

export const {
  resetCouponList,
  resetDownloadStatus,
  resetError,
  setCouponTotalCount,

  getV2CouponListRequest,
  getV2CouponListSuccess,
  getV2CouponListFailure,
  getV2CouponListMore,

  getMyCouponsCountRequest,
  getMyCouponsCountSuccess,
  getMyCouponsCountFailure,

  getMyCouponListRequest,
  getMyCouponListSuccess,
  getMyCouponListFailure,

  issueV2CouponRequest,
  issueV2CouponSuccess,
  issueV2CouponFailure,

  downloadV2CouponRequest,
  downloadV2CouponSuccess,
  downloadV2CouponFailure,

  downloadCamfitCouponRequest,
  downloadCamfitCouponSuccess,
  downloadCamfitCouponFailure,

  setNeedCertification,

  getCouponDetailRequest,
  getCouponDetailSuccess,
  getCouponDetailFailure,
} = v2CouponSlice.actions;

export default v2CouponSlice.reducer;
