import styled from 'styled-components';

export const Product = styled.section`
  margin: 0 0 0 calc(50vw - 50px);
  @media screen and (max-width: 960px) {
    margin: 0 auto;
  }
  .MuiContainer-root {
    margin: 0;
    @media screen and (max-width: 960px) {
      margin: 0 auto;
    }
  }
`;

export const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  max-height: 100vh;
`;
