import { PayloadAction } from '@reduxjs/toolkit';
import { takeEvery, all, take, put, select } from 'redux-saga/effects';
import {
  getCampAnnouncesFailure,
  getCampAnnouncesRequest,
  getCampAnnouncesSuccess,
  getSingleCampAnnounceFailure,
  getSingleCampAnnounceRequest,
  getSingleCampAnnounceSuccess,
  addPage,
} from 'store/reducers/campAnnounces';
import { RootState } from 'store/reducers';
import { getCampAnnounces, getSingleCampAnnounce } from 'api';
import { customHistory } from 'App';
import { createFetchAction } from './createFetchAction';

function* getMoreCampAnnounces() {
  while (true) {
    const action: PayloadAction<number> = yield take(addPage.type);

    const id: string = yield select(
      (state: RootState) => state.campReducer.camp.id,
    );

    yield put(
      getCampAnnouncesRequest({
        search: '',
        campId: id,
        page: action.payload,
        pageSize: 10,
      }),
    );
  }
}

function* fetchCampAnnounces() {
  yield takeEvery(
    getCampAnnouncesRequest.type,
    createFetchAction(
      getCampAnnounces,
      getCampAnnouncesSuccess,
      getCampAnnouncesFailure,
    ),
  );
}

function* fetchSingleCampAnnounce() {
  yield takeEvery(
    getSingleCampAnnounceRequest.type,
    createFetchAction(
      getSingleCampAnnounce,
      getSingleCampAnnounceSuccess,
      getSingleCampAnnounceFailure,
      undefined,
      function* fail() {
        yield alert('공지를 찾을 수 없습니다.');
        customHistory.replace('/');
      },
    ),
  );
}

export default function* getCampAnnouncesSaga() {
  yield all([
    fetchCampAnnounces(),
    fetchSingleCampAnnounce(),
    getMoreCampAnnounces(),
  ]);
}
