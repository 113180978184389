import ko from 'date-fns/locale/ko';
import { format, differenceInCalendarDays, intervalToDuration } from 'date-fns';

// export const changeHyphenDateStringFormat = (
//   date: string, // 샵바이에서 사용하는 'YYYY.MM.DD hh:mm:ss' 형태와 같은 dateString
//   dateFormat: string = 'yy.MM.dd',
// ) => {
//   if (date) {
//     return format(ate, dateFormat, {
//       locale: ko,
//     });
//   }
// };

export const makeHyphenDateStringToSlash = (
  dateString: string, // 'YYYY-MM-DD' 형태와 같은 하이픈이 포함된 dateString
) => {
  return dateString.replace(/-/g, '/');
};

export function getKoreaDate(date: number | Date) {
  const now = new Date();

  const koreaTimeOffset = 9 * 60 * 60 * 1000; // 9시간을 밀리초로 변환
  const utc =
    (typeof date === 'number' ? date : date.getTime()) +
    now.getTimezoneOffset() * 60000; // UTC 시간 계산
  const koreaDate = new Date(utc + koreaTimeOffset);
  return koreaDate;
}

export const changeKoreaDateFormat = (
  date: Date | number,
  dateFormat: string = 'yy.MM.dd',
) => {
  if (date) {
    // 한국 시간 포맷팅
    const koreaTime = getKoreaDate(date);

    return format(koreaTime, dateFormat, {
      locale: ko,
    });
  }
};

export const changeDateFormat = (
  date: Date | number,
  dateFormat: string = 'yy.MM.dd',
) => {
  if (date) {
    return format(date, dateFormat, {
      locale: ko,
    });
  }
};

export const calculateDiffInDays = (
  endDate: Date | number,
  startDate: Date | number,
) => {
  return differenceInCalendarDays(endDate, startDate);
};

export const calculateBookableLimitDate = (
  bookableMonth: number,
  dailyOpenHour: number,
) => {
  const now = new Date();
  return new Date(
    now.setDate(
      now.getDate() +
        (dailyOpenHour > 0 && now.getHours() < dailyOpenHour ? -1 : 0) +
        30 * bookableMonth,
    ),
  );
};

export const changeRegisterDateFormat = (date: Date | number) => {
  const now = new Date();
  const dateInterval = intervalToDuration({ start: date, end: now });
  const { seconds, minutes, hours, days, months, years } = dateInterval;
  let intervalMark = '';

  switch (true) {
    case years && years > 0:
      intervalMark = `${years}년 전`;
      break;

    case months && months < 12:
      intervalMark = `${months}달 전`;
      break;

    case days && days < 31:
      intervalMark = `${days}일 전`;
      break;

    case hours && hours < 24:
      intervalMark = `${hours}시간 전`;
      break;

    case minutes && minutes < 60:
      intervalMark = `${minutes}분 전`;
      break;

    case (seconds as number) >= 0 || (seconds as number) < 60:
      intervalMark = '방금 전';
      break;

    default:
      break;
  }

  return intervalMark;
};
